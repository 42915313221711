$primary: #264b59;
$secondary: #008995;
$gray: #ccc;
$lightGray: #ebebeb;
$red: #dc3545;

.btn {
	&.btn-cancel {
  	background-color: $lightGray;
    &:hover {
      background-color: $gray !important;
    }
  }

  &.btn-secondary {
    &:hover {
      color: white !important;
    }
    &:disabled {
      border-color: $gray;
      background-color: $gray;
    }
    color: white;
  }

  &.btn-outline-secondary {
    &:hover {
      color: white !important;
      border-color: $gray;
      background-color: $gray;
    }
  }

  &.btn-success {
    color: white;
  }
}

body {
  position: relative;
}

.tox .tox-button[title="Save"] {
  background-color: $secondary;
  &:hover:not(:disabled) {
    border-color: red;
  }
  
}

.form-select, input.form-control, .react-datepicker__input-container input {
  border: 1px solid hsl(0, 0%, 80%) !important;;
  border-radius: 4px !important;
  outline:none !important;

  &:focus {
    border-color: $secondary !important;
    box-shadow: 0 0 0 1px $secondary !important;
  }

  &.is-invalid {
    border-color: $red !important;
    box-shadow: 0 0 0 1px $red !important;
  }
}

.css-t3ipsp-control {
  border-color: $secondary !important;
  box-shadow: 0 0 0 1px $secondary !important;
}

@import "bootstrap";

